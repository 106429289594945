import axios from "axios";
import Storage from "./Storage";

const client = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use(
  (config) => {
    const token = Storage.getUserToken();
    const configWithChanges = config;
    if (token) {
      configWithChanges.headers.Authorization = `Bearer ${token}`;
    }
    return configWithChanges;
  },
  (err) => Promise.reject(err)
);

client.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err?.response?.data) {
      if (err.response.status === 401) {
        Storage.removeUserToken();
      }
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }
);

export default client;
