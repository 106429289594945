import { forwardRef } from "react";
import { Container, Typography, Box, Grid } from "@material-ui/core";
// Assets
import { BenefitsDestination, BenefitsInfo, BenefitsReadOffline } from "assets/images";
// Styles
import { useStyles } from "./LandingPage.styles";

const benefits = [
  {
    id: 0,
    title: "Novos Destinos",
    text:
      "Acesso exclusivo ao conteúdo de viagem para você eleger seu novo destino em qualquer lugar do mundo. Uma abordagem única onde você escolhe se quer ser turista ou local!",
    image: BenefitsDestination,
  },
  {
    id: 1,
    title: "Diversidade de informações",
    text:
      "Revistas, dicas com vídeos e artigos fonográficos ao seu alcance! Tudo isso para você planejar o melhor roteiro de viagem.",
    image: BenefitsInfo,
  },
  {
    id: 2,
    title: "Leitura online e off-line",
    text: "Poderá baixar edições completas de revistas e artigos podendo ler em suas viagens, mesmo sem internet.",
    image: BenefitsReadOffline,
  },
];

const LandingBenefits = forwardRef<HTMLElement>((_, ref) => {
  const classes = useStyles();

  return (
    <section ref={ref}>
      <Container>
        <Box py={"60px"}>
          <Box mb={6}>
            <Typography variant="h3" align="center" color="textSecondary">
              <Box fontWeight="500">Conheça as vantagens</Box>
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {benefits.map((bene) => {
              return (
                <Grid key={bene.id} item sm={12} md={4} alignItems="center">
                  <Box display="flex" alignItems="center" flexDirection="column">
                    <img className={classes.benefitsImage} src={bene.image} alt={bene.title} />
                    <Typography variant="h5" align="center" gutterBottom>
                      <Box fontWeight="500">{bene.title}</Box>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <Box lineHeight="26px">{bene.text}</Box>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </section>
  );
});

export default LandingBenefits;
