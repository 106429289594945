import { forwardRef } from "react";
import { Box, Grid, Typography, Container, Hidden } from "@material-ui/core";
// Styles
import { useStyles } from "./LandingPage.styles";
// Assets
import { MockupMagazine, MockupTips } from "assets/images";
// Components
import LandingStoreButtons from "./LandingStoreButtons";

const LandingDownload = forwardRef<HTMLElement>((_, ref) => {
  const classes = useStyles();
  return (
    <section ref={ref} className={classes.downloadSection}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={12} md={5}>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h3" color="secondary">
                <Box fontWeight="500">Baixe agora nosso aplicativo</Box>
              </Typography>
              <Typography variant="body1" color="secondary">
                Disponivel para Android e iOS
              </Typography>
              <LandingStoreButtons mt={4} />
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item sm={12} md={7}>
              <Box display="flex" justifyContent="flex-end">
                <img className={classes.downloadFirstImage} src={MockupTips} alt="" />
                <img className={classes.downloadLastImage} src={MockupMagazine} alt="" />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </section>
  );
});

export default LandingDownload;
