import { atom, selector, useSetRecoilState } from "recoil";
// Types
import type { MagazineSortKeys, SnackProps, User } from "./types";
// API
import { getMagazines, getPublishers } from "api";

export const snackbarState = atom<SnackProps>({
  key: "snackbarState",
  default: {
    open: false,
    message: "Teste",
    type: "error",
  },
});

export const userState = atom<User | null>({
  key: "userState",
  default: null,
});

export const useMagazineQueryId = atom({
  key: "magazineQueryId",
  default: 0,
});

export const usePublisherQuery = selector({
  key: "getPublishers",
  get: async () => {
    const publishers = await getPublishers();
    return publishers;
  },
});

export const useMagazineQuery = selector({
  key: "getMagazines",
  get: async ({ get }) => {
    get(useMagazineQueryId);
    const response = await getMagazines();
    return response;
  },
});

export function useRefreshMagazines() {
  const setMagazineQueryId = useSetRecoilState(useMagazineQueryId);
  return () => {
    setMagazineQueryId((requestID) => requestID + 1);
  };
}

export const sortMagazinesState = atom<MagazineSortKeys>({
  key: "sortMagazinesState",
  default: "CREATED_ASC",
});

export const filterPubMagazinesState = atom<string | null>({
  key: "filterPubMagazinesState",
  default: null,
});

export const sortAndFilterMagazinesList = selector({
  key: "sortedMagazinesList",
  get: ({ get }) => {
    const magazines = get(useMagazineQuery);
    const sort = get(sortMagazinesState);
    const publishedSelected = get(filterPubMagazinesState);
    let clonned = [...magazines];
    if (publishedSelected) {
      clonned = [...clonned.filter((mag) => mag.publisherId === publishedSelected)];
    } else {
      clonned = [...magazines];
    }

    switch (sort) {
      case "CREATED_DESC":
        return [...clonned.reverse()];
      case "MOSTVIEWED_ASC":
        return [...clonned.sort((a, b) => b.viewCount! - a.viewCount!)];
      case "MOSTVIEWED_DESC":
        return [...clonned.sort((a, b) => a.viewCount! - b.viewCount!)];
      default:
        return [...clonned];
    }
  },
});
