import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
} from "@material-ui/core";
import { useField } from "formik";

export type RadioOption = {
  id: string;
  name: string;
};

type RadioInputProps = {
  options: RadioOption[];
  label: string;
  name: string;
};

const useStyles = makeStyles({
  formHelperText: {
    marginTop: -10,
  },
});

const RadioInput = (props: RadioInputProps) => {
  const classes = useStyles();
  const { options, name, label } = props;
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);
  return (
    <FormControl component="fieldset" error={hasError} margin="dense">
      <FormLabel component="legend" color="primary">
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        row
        value={field.value}
        onChange={(e) => {
          const value = e.target.value;
          helpers.setValue(value);
        }}
        onBlur={field.onBlur}
        onFocus={() => {
          helpers.setTouched(true);
        }}
      >
        {options.map((opt) => (
          <FormControlLabel key={opt.id} value={opt.id} label={opt.name} control={<Radio color="primary" />} />
        ))}
      </RadioGroup>
      <FormHelperText className={classes.formHelperText}>{hasError && meta.error}</FormHelperText>
    </FormControl>
  );
};

export default RadioInput;
