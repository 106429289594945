import React, { useState } from "react";
import { Box, Menu, MenuItem, List, ListItem, ListItemText } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import { usePublisherQuery, sortMagazinesState, filterPubMagazinesState } from "state/atoms";
// Atoms
import type { MagazineSortKeys } from "state/types";

const MagazinesSortContent: Record<MagazineSortKeys, string> = {
  CREATED_ASC: "Mais Novos",
  CREATED_DESC: "Mais Antigos",
  MOSTVIEWED_ASC: "Mais Vistos",
  MOSTVIEWED_DESC: "Menos Vistos",
};

const MagazineListHeader = () => {
  const publishers = useRecoilValue(usePublisherQuery);
  const [sort, setSort] = useRecoilState(sortMagazinesState);
  const [selectedPublisherId, setSelectedPublisher] = useRecoilState(filterPubMagazinesState);
  const [menuSortEl, setMenuSortEl] = useState<null | HTMLElement>(null);
  const [menuFilterEl, setMenuFilterEl] = useState<null | HTMLElement>(null);

  const publisherOptions = publishers.map((l) => ({
    value: l.id,
    label: l.name,
  }));

  const publisherSelected = publisherOptions.find((l) => l.value === selectedPublisherId)?.label || "Todas";

  // Sort
  const handleOpenMenu = (evt: React.MouseEvent<HTMLElement>) => {
    setMenuSortEl(evt.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuSortEl(null);
  };
  const handleSelectSort = (sortKey: MagazineSortKeys) => {
    setSort(sortKey);
    setMenuSortEl(null);
  };

  // Filter
  const handleOpenFilterMenu = (evt: React.MouseEvent<HTMLElement>) => {
    setMenuFilterEl(evt.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setMenuFilterEl(null);
  };
  const handleSelectPublished = (pubId: string | null) => {
    setSelectedPublisher(pubId);
    setMenuFilterEl(null);
  };

  const keys = Object.keys(MagazinesSortContent) as MagazineSortKeys[];
  return (
    <Box display="flex" justifyContent="flex-end">
      <List component="nav">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="ordenar por:"
          onClick={handleOpenMenu}
        >
          <ListItemText primary="Ordernar por:" secondary={MagazinesSortContent[sort]} />
        </ListItem>
      </List>
      <Menu anchorEl={menuSortEl} open={Boolean(menuSortEl)} onClose={handleCloseMenu}>
        {keys.map((magSortKey) => {
          return (
            <MenuItem onClick={() => handleSelectSort(magSortKey)} key={magSortKey}>
              {MagazinesSortContent[magSortKey]}
            </MenuItem>
          );
        })}
      </Menu>
      <List component="nav">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="mostrar por"
          onClick={handleOpenFilterMenu}
        >
          <ListItemText primary="Mostrar por:" secondary={publisherSelected} />
        </ListItem>
      </List>
      <Menu anchorEl={menuFilterEl} open={Boolean(menuFilterEl)} onClose={handleCloseFilterMenu}>
        <MenuItem onClick={() => handleSelectPublished(null)}>Todas</MenuItem>
        {publisherOptions.map((pub) => {
          return (
            <MenuItem onClick={() => handleSelectPublished(pub.value)} key={pub.value}>
              {pub.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default MagazineListHeader;
