import { useMemo, useState, useEffect } from "react";
import { makeStyles, Typography, Box, Theme, colors, FormHelperText } from "@material-ui/core";
import { useField } from "formik";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useSetRecoilState } from "recoil";
// State
import { snackbarState } from "state/atoms";

interface DropzoneInputProps extends DropzoneOptions {
  setValue?(file: File): void;
  name: string;
  showPreview?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneText: {
    fontWeight: 500,
    color: colors.grey["400"],
  },
  dropzoneFileName: {
    textOverflow: "ellipsis",
    overflow: "auto",
    whiteSpace: "nowrap",
  },
  dropzoneStyleBox: {
    borderRadius: 5,
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: colors.grey["300"],
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.grey["100"],
    outline: "none",
    transition: "all .3s ease",
    cursor: "pointer",
  },
  dropzoneAccepted: {
    borderColor: colors.green["500"],
    backgroundColor: colors.green["50"],
  },
  dropzoneRejected: {
    borderColor: colors.red["400"],
    backgroundColor: colors.red["50"],
  },
  dropzoneImagePreview: {
    maxHeight: 200,
    width: "100%",
  },
}));

const DropzoneInput = (props: DropzoneInputProps) => {
  const { name, showPreview, ...restProps } = props;
  const setSnackbar = useSetRecoilState(snackbarState);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta, helper] = useField(name);
  const classes = useStyles();
  const [preview, setPreview] = useState("");

  const hasInitialValue = useMemo(() => {
    return typeof meta.initialValue === "string" && typeof meta.value === "string";
  }, [meta.initialValue, meta.value]);

  useEffect(() => {
    if (hasInitialValue) {
      setPreview(meta.initialValue);
    }
  }, [hasInitialValue]);

  useEffect(
    () => () => {
      URL.revokeObjectURL(preview);
    },
    [preview]
  );

  const options: DropzoneOptions = {
    maxFiles: 1,
    accept: "application/pdf",
    maxSize: 30000000,
    onDropRejected: () => {
      setSnackbar({
        message: "Arquivo muito grande ou inválido",
        type: "error",
        open: true,
      });
    },
    onDrop: (e) => {
      if (showPreview && e.length > 0) {
        const firstFile = e[0];
        const url = URL.createObjectURL(firstFile);
        setPreview(url);
      }
      helper.setValue(e[0]);
    },
    ...restProps,
  };

  const { getInputProps, getRootProps, isDragReject, isDragAccept, acceptedFiles } = useDropzone(options);

  const className = useMemo(() => {
    const styleAccept = isDragAccept ? classes.dropzoneAccepted : "";
    const styleRejected = isDragReject ? classes.dropzoneRejected : "";
    return `${classes.dropzoneStyleBox} ${styleAccept} ${styleRejected}`;
  }, [isDragAccept, isDragReject]);

  const inputProps = getInputProps();
  const divProps = getRootProps({
    className,
    onFocus: () => {
      helper.setTouched(true);
    },
    onBlur: () => {
      helper.setTouched(false);
    },
  });

  const hasError = !!(meta.touched && meta.error);

  return (
    <>
      <div {...divProps}>
        <input {...inputProps} />
        <Typography className={classes.dropzoneText} variant="body2">
          Selecione ou arraste o arquivo
        </Typography>
      </div>
      {hasInitialValue && preview && (
        <Box mt={2}>
          <img className={classes.dropzoneImagePreview} src={preview} alt={`preview ${preview}`} />
        </Box>
      )}
      {acceptedFiles.length > 0 && (
        <Box display="flex" mt={2}>
          <Box width={showPreview ? 0.4 : 1}>
            <Typography variant="subtitle2">Nome: </Typography>
            <Typography variant="subtitle1" className={classes.dropzoneFileName}>
              {acceptedFiles[0].name}
            </Typography>
            <Typography variant="subtitle2">Tamanho:</Typography>
            <Typography variant="subtitle1">{(acceptedFiles[0].size / 1000000).toFixed(2)} Mb</Typography>
          </Box>
          {showPreview && (
            <Box width={0.6} display="flex" justifyContent="center">
              <img className={classes.dropzoneImagePreview} src={preview} alt={`preview ${preview}`} />
            </Box>
          )}
        </Box>
      )}
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
};

export default DropzoneInput;
