import { Dialog, DialogActions, DialogTitle, Button } from "@material-ui/core";

interface MagazineDialogProps {
  onClose(): void;
  onUpdate(): void;
  onOpenPdf(): void;
  open: boolean;
}

const MagazineDialog = (props: MagazineDialogProps) => {
  const { open, onOpenPdf, onClose, onUpdate } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>O que deseja fazer?</DialogTitle>
      <DialogActions>
        <Button onClick={onOpenPdf}>Abrir Revista</Button>
        <Button onClick={onUpdate}>Editar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MagazineDialog;
