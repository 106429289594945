import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";

type InputProps = TextFieldProps & {
  name: string;
  parser?(value: string): string;
};

const Input = (props: InputProps) => {
  const { name, parser, ...restProps } = props;
  const [field, meta] = useField(name);
  const error = !!(meta.touched && meta.error);
  function customOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (parser) {
      const copyEvent = event;
      const value = parser(event.target.value);
      copyEvent.target.value = value;
      field.onChange(copyEvent);
    } else {
      field.onChange(event);
    }
  }
  return (
    <TextField {...field} {...restProps} onChange={customOnChange} error={error} helperText={error && meta.error} />
  );
};

export default Input;
