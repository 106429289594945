import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Template, ProtectedRoute } from "components";
// import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import ArticlesPage from "./pages/articles/ArticlesPage";
import MagazinesPage from "./pages/magazines/MagazinesPage";
// import NewsPage from "./pages/news/NewsPage";
import MsisdnPage from "./pages/status/StatusPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import LandingPage from "./pages/landing/LandingPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Template>
            <LoginPage />
          </Template>
        </Route>
        <Route component={LandingPage} exact path="/" />
        <Route component={PrivacyPage} exact path="/privacidade" />
        {/* <ProtectedRoute component={DashboardPage} exact path="/admin" /> */}
        <ProtectedRoute component={MagazinesPage} exact path="/admin" />
        <ProtectedRoute component={ArticlesPage} exact path="/admin/artigos" />
        <ProtectedRoute component={MagazinesPage} exact path="/admin/revistas" />
        <ProtectedRoute component={MsisdnPage} exact path="/admin/verificar" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
