import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: "linear-gradient(304.78deg, #B065CC 0%, #349CCC 100%)",
    height: "100vh",
    position: "relative",
  },
  headerContainer: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerLogo: {
    marginRight: theme.spacing(2),
    height: 38,
  },
  headerNavLink: {
    cursor: "pointer",
    padding: `0 ${theme.spacing(2)}px`,
    "& p": {
      transition: `text-shadow .4s ease`,
    },
    "&:hover p": {
      textShadow: "0 0 .65px #fff, 0 0 .65px #fff",
    },
  },
  headerContent: {
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "initial",
      flexDirection: "column",
    },
  },
  headerTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
  },
  headerSubTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },

  headerMockupImage: {
    marginRight: -50,
    width: 349,
    height: 639,
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      height: "initial",
      width: "initial",
      maxWidth: "100%",
    },
  },
  benefitsImage: {
    marginBottom: theme.spacing(2),
  },
  magazinesSection: {
    backgroundColor: "#E8F7FE",
  },
  magazinesImg: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tipsContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  tipsImage: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  downloadSection: {
    backgroundColor: "#B065CC",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(4)}px 0`,
    },
  },
  downloadFirstImage: {
    marginBottom: theme.spacing(5),
  },
  downloadLastImage: {
    alignSelf: "flex-end",
  },
  footer: {
    padding: "50px 0",
    backgroundColor: "#333333",
  },
  footerContent: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  footerTermsButton: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  storeButtonIcon: {
    cursor: "pointer",
  },
  storeImage: {
    height: 40,
  },
  outlinedButton: {
    fontSize: 16,
    fontWeight: 700,
    borderRadius: 8,
    height: 52,
    width: 288,
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    cursor: "pointer",
    border: "2px solid #fff",
    transition: `background-color .3s`,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.04)",
    },
  },
}));

export { useStyles };
