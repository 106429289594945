import React, { useRef } from "react";
// UI
import { Typography, Container, Box, Hidden } from "@material-ui/core";
// Assets
import { LogoWhite, MockupHome } from "assets/images";
// Components
import LandingStoreButtons from "./components/LandingStoreButtons";
import LandingBenefits from "./components/LandingBenefits";
import LandingMagazines from "./components/LandingMagazines";
import LandingTips from "./components/LandingTips";
import LandingDownload from "./components/LandingDownload";
import LandingFooter from "./components/LandingFooter";
// Styles
import { useStyles } from "./components/LandingPage.styles";
// Utils
import { useDevice } from "utils/useDevice";
// Config
import config from "config";

const menuLinks = [
  { id: 0, name: "Vantagens", title: "benefits" },
  { id: 1, name: "Revistas", title: "magazines" },
  { id: 2, name: "Curiosidade", title: "curiosity" },
  { id: 3, name: "Baixe Agora", title: "download" },
];

type RefHtmlEl = {
  [key: string]: HTMLElement | null;
};

const LandingPage = () => {
  const classes = useStyles();
  const { isIos } = useDevice();

  const sectionRefs = useRef<RefHtmlEl>({}!);

  function onScroll(elementName: string) {
    const selectedElement = sectionRefs.current[elementName];
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  function goStore() {
    window.location.href = isIos ? config.ios : config.android;
  }

  return (
    <main>
      <header className={classes.header}>
        <Container className={classes.headerContainer}>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Box display="flex" alignItems="center">
              <img src={LogoWhite} alt="logo oidestinos" className={classes.headerLogo} />
              <Hidden smDown>
                {menuLinks.map((link) => {
                  return (
                    <a key={link.id} onClick={() => onScroll(link.title)} className={classes.headerNavLink}>
                      <Typography variant="body2" color="secondary">
                        {link.name}
                      </Typography>
                    </a>
                  );
                })}
              </Hidden>
            </Box>
            <Hidden smDown>
              <LandingStoreButtons ml="auto" />
            </Hidden>
          </Box>
          <Box display="flex" justifyContent="space-between" flex={1} className={classes.headerContent}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              width={["auto", "auto", 720]}
              flex={[1, 1, "initial"]}
              mb={[2, 2, 0]}
            >
              <Typography className={classes.headerTitle} variant="h2" color="secondary">
                <Box fontWeight="500">Explore novos destinos!</Box>
              </Typography>
              <Typography className={classes.headerSubTitle} variant="h4" color="secondary">
                <Box fontWeight="300">Oi Destinos, você escolhe sua próxima viagem em um só lugar!</Box>
              </Typography>
              <Box mt={4}>
                <button className={classes.outlinedButton} onClick={goStore}>
                  Experimente
                </button>
              </Box>
            </Box>
            <img className={classes.headerMockupImage} src={MockupHome} alt="iphone mockup home" />
          </Box>
        </Container>
      </header>
      <LandingBenefits ref={(el) => (sectionRefs.current["benefits"] = el)} />
      <LandingMagazines ref={(el) => (sectionRefs.current["magazines"] = el)} />
      <LandingTips ref={(el) => (sectionRefs.current["curiosity"] = el)} />
      <LandingDownload ref={(el) => (sectionRefs.current["download"] = el)} />
      <LandingFooter />
    </main>
  );
};

export default LandingPage;
