import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Box } from "@material-ui/core";
// Atoms
import { useRecoilValue } from "recoil";
import { usePublisherQuery, userState } from "state/atoms";
// Type
import { Category, MagazineCreateReq } from "types";
// Form
import { Formik, Form } from "formik";
import * as yup from "yup";
// UI
import { RadioOption } from "components/Radio/Radio";
import { Button, DropzoneInput, RadioInput, Input } from "components";

const createValidationSchema = yup.object({
  file: yup.mixed().required("Você precisa escolher um arquivo"),
  categoryId: yup.string().required("Você precisa escolher uma opção"),
  publisherId: yup.string().required("Você precisa escolher uma opção"),
});
const updateValidationSchema = yup.object({
  categoryId: yup.string().required("Você precisa escolher uma opção"),
  publisherId: yup.string().required("Você precisa escolher uma opção"),
});

const useStyles = makeStyles({
  dialogContent: {
    width: 400,
  },
});

interface DialogFormProps<T extends MagazineCreateReq> {
  categories: Category[];
  onCloseDialog(): void;
  open: boolean;
  initialValues: T;
  onSubmit(e: T): void;
  isEditing: boolean;
}

function DialogForm<T extends MagazineCreateReq>(props: DialogFormProps<T>) {
  const publishers = useRecoilValue(usePublisherQuery);
  const user = useRecoilValue(userState);
  const classes = useStyles();
  const { open, onCloseDialog, onSubmit, initialValues, categories, isEditing } = props;

  function prepareOptions<T extends Category>(listIn: T[]): RadioOption[] {
    return listIn.map((t) => ({
      name: t.name,
      id: t.id,
    }));
  }

  const isAdmin = user?.role === "ADMIN";

  const categoryOptions = prepareOptions(categories);
  const publisherOptions = prepareOptions(publishers);

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Formik
        initialValues={initialValues}
        validationSchema={!isEditing ? createValidationSchema : updateValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <DialogTitle id="form-dialog-title">{isEditing ? "Editar" : "Nova"} Revista</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <RadioInput label="Categoria:" name="categoryId" options={categoryOptions} />
                <RadioInput label="Editora:" name="publisherId" options={publisherOptions} />
                <Input name="publishedAt" type="date" id="date" label="Publicado em:" margin="normal" />
                <Box my={2} />
                {!isEditing && <DropzoneInput name="file" />}
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={onCloseDialog} color="primary">
                  Cancelar
                </Button>
                <Button
                  disabled={isEditing && !isAdmin}
                  loading={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {isEditing ? "Editar" : "Criar"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default DialogForm;
