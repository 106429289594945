import { createMuiTheme, colors } from "@material-ui/core";

// Sizes 18(400, 700), 48(500), 24(500), 36(300),

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        background: "linear-gradient(304.78deg, #B065CC 0%, #349CCC 100%)",
        transition:
          "background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
          backgroundColor: "initial",
          background: "linear-gradient(304.78deg, #B065CC 40%, #349CCC 100%)",
        },
        "&:disabled": {
          background: "initial",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#349CCC",
    },
    secondary: {
      main: "#fff",
      light: "#D9DBE1",
    },
    success: {
      main: "#4caf50",
    },
    error: {
      main: "#EF5350",
      light: colors.red[200],
    },
    text: {
      primary: "#666666",
      secondary: "#333333",
    },
  },
});

theme.typography.h3 = {
  lineHeight: "64px",
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "32px",
    fontSize: "2rem",
  },
};

export default theme;
