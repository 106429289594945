import React from "react";
// Material Core
import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
// Icons
import ExitIcon from "@material-ui/icons/ExitToApp";

const pages = [
  // { page: "/admin", key: "home", name: "Dashboard" },
  { page: "/admin/artigos", key: "articles", name: "Artigos" },
  { page: "/admin/revistas", key: "magazines", name: "Revistas" },
  // { page: "/noticias", key: "news", name: "Notícias" },
  { page: "/admin/verificar", key: "status", name: "Consultar Status" },
];

interface DrawerListProps {
  onLogout(): void;
  onGoPage(page: string): void;
  activedPage?: string;
}

const DrawerList = (props: DrawerListProps) => {
  const { onLogout, onGoPage, activedPage } = props;
  return (
    <>
      <List component="nav">
        {pages.map((page) => {
          const selected = page.page === activedPage;
          return (
            <ListItem selected={selected} key={page.key} button onClick={() => onGoPage(page.page)}>
              <ListItemText>{page.name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <List component="nav">
        <Divider />
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default DrawerList;
