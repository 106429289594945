import { Link } from "react-router-dom";
import { Container, Typography, Box } from "@material-ui/core";
// Styles
import { useStyles } from "./LandingPage.styles";

const LandingBenefits = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Box className={classes.footerContent} display="flex" justifyContent="space-between">
          <Box mb={[1, 1, 0]}>
            <Typography variant="body1" color="secondary">
              © Copyright 2021 | Todos os direitos reservados <strong>Oi Destinos</strong>
            </Typography>
          </Box>
          <Link className={classes.footerTermsButton} to="/privacidade">
            <Typography variant="body1" color="secondary">
              Política e privacidade | Termo de uso
            </Typography>
          </Link>
        </Box>
      </Container>
    </footer>
  );
};

export default LandingBenefits;
