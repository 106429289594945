import { CircularProgress, Backdrop, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default Loading;
