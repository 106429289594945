import { useEffect, useState, useMemo, useCallback } from "react";
// State
import { useSetRecoilState, useRecoilValue } from "recoil";
import { snackbarState, userState } from "state/atoms";
// UI
import { Loading, ModalDelete, FloatingAddButton, List, ListItem } from "components";
// Services
import { getArticles, createArticle, removeArticle } from "api";
// Types
import { Article } from "types";
// Utils
import { dateLegible } from "utils/dateUtils";
// Page Components
import ArticleForm from "./components/ArticlesForm";

type ArticlesInitialValues = {
  file: File;
};

const ArticlesPage = () => {
  const setSnack = useSetRecoilState(snackbarState);
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [deleteItemId, setDeleteItemId] = useState<string>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);

  const openFormDialog = () => {
    setFormDialogOpen(true);
  };
  const closeFormDialog = () => {
    setFormDialogOpen(false);
  };

  const onOpenModalDelete = (id: string) => {
    setDeleteModalOpen(true);
    setDeleteItemId(id);
  };
  const onCloseModalDelete = () => {
    setDeleteModalOpen(false);
  };

  const fetchArticles = useCallback(async () => {
    setLoading(true);
    const response = await getArticles();
    setArticles(response);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const onDeleteConfirm = async () => {
    if (deleteItemId) {
      setLoading(true);
      await removeArticle(deleteItemId);
      setLoading(false);
      onCloseModalDelete();
      await fetchArticles();
    }
  };

  const onSubmit = async (values: ArticlesInitialValues) => {
    try {
      const response = await createArticle(values.file);
      setSnack({
        open: true,
        type: "success",
        message: "Criado com sucesso",
      });
      setArticles((p) => [...p, response]);
      closeFormDialog();
    } catch (error: any) {
      setSnack({
        open: true,
        type: "error",
        message: error?.message,
      });
    }
  };

  const articleSelectedDay = useMemo(() => {
    if (deleteItemId) {
      const itemFromList = articles.find((art) => art.id === deleteItemId);

      return itemFromList ? dateLegible(itemFromList?.createdAt) : "";
    }
  }, [deleteItemId]);

  return (
    <>
      {loading && <Loading />}
      <List
        title="Artigos"
        emptyMessage="Nenhum artigo encontrado"
        data={articles}
        renderItem={(article) => (
          <ListItem
            key={article.id}
            onClick={() => window.open(article.pdfUrl, "_blank")}
            title={dateLegible(article.createdAt)}
            onDelete={() => onOpenModalDelete(article.id)}
            img={article.thumbnail}
          />
        )}
      />
      <FloatingAddButton onOpen={openFormDialog} />
      {user?.role === "ADMIN" && (
        <ModalDelete
          open={deleteModalOpen}
          onClose={onCloseModalDelete}
          title={`Deseja remover o artigo do dia ${articleSelectedDay}?`}
          onSuccess={onDeleteConfirm}
        />
      )}

      <ArticleForm
        open={formDialogOpen}
        onCloseDialog={closeFormDialog}
        onSubmit={onSubmit}
        initialValues={{
          file: null!,
        }}
      />
    </>
  );
};

export default ArticlesPage;
