import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import Template from "../Template/Template";
// Services
import Storage from "services/Storage";

type ProtectedRouteProps = RouteProps & {
  component: React.FC<any>;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component: Component, ...rest } = props;

  const userToken = Storage.getUserToken();

  return (
    <Template>
      <Route
        render={(p) => {
          return userToken ? <Component {...p} /> : <Redirect to="/login" />;
        }}
        {...rest}
      />
    </Template>
  );
};

export default ProtectedRoute;
