import React, { useState, useMemo } from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Button, Input } from "components";
import { Formik, Form } from "formik";
import { getMsisdnStatus } from "api";
// Types
import { MsisdnStatusResponse } from "types";
// Utils
import { normalizePhone, normalizeNums } from "utils/normalizers";

type StatusFormValues = {
  msisdn: string;
};

const validateForm = (values: StatusFormValues) => {
  const errors: Partial<StatusFormValues> = {};
  if (!values.msisdn) {
    errors.msisdn = "Campo obrigatório";
  } else if (normalizeNums(values.msisdn).length < 10) {
    errors.msisdn = "Telefone incorreto";
  }
  return errors;
};

const useStyles = makeStyles(() => {
  return {
    fontColorError: {
      color: "#EF5350",
    },
    fontColorSuccess: {
      color: "#4caf50",
    },
  };
});

const StatusPage = () => {
  const classes = useStyles();
  const [response, setResponse] = useState<MsisdnStatusResponse | null>(null);
  const [responseStatus, setResponseStatus] = useState<"error" | "success" | null>(null);

  const onSubmit = async (values: StatusFormValues) => {
    try {
      const normalizePhone = "55" + normalizeNums(values.msisdn);
      const response = await getMsisdnStatus(normalizePhone);
      if (response && response.status === "Subscribed") {
        setResponseStatus("success");
      } else {
        setResponseStatus("error");
      }
      setResponse(response);
    } catch (error) {
      setResponseStatus("error");
      setResponse(error.message);
    }
  };

  const initialValues = {
    msisdn: "",
  };

  const msgResponse = useMemo(() => {
    if (responseStatus) {
      if (responseStatus === "success") {
        return "Usuário com acesso";
      } else {
        return "Usuário sem acesso";
      }
    }
    return "";
  }, [responseStatus]);
  return (
    <Container maxWidth="xs">
      <Box my={3}>
        <Typography variant="h6">Consultar Msisdn</Typography>
      </Box>
      <Paper>
        <Box p={3}>
          <Formik validate={validateForm} initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Input parser={normalizePhone} name="msisdn" label="Celular" fullWidth />
                  <Box my={2} display="flex" justifyContent="center">
                    <Button loading={isSubmitting} color="primary" variant="contained" type="submit">
                      Consultar
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
          {responseStatus && (
            <>
              <Typography variant="h6">Resposta</Typography>
              <Typography
                className={responseStatus === "error" ? classes.fontColorError : classes.fontColorSuccess}
                variant="body1"
              >
                {msgResponse}{" "}
              </Typography>
              {response && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="response-content"
                    id="response-header"
                  >
                    <Typography>Resposta</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ width: "100%" }}>
                    <code>{JSON.stringify(response).replaceAll(',"', ', "')}</code>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default StatusPage;
