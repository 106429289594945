import React from "react";
import { Container, Typography } from "@material-ui/core";
// Data
import allterms from "./terms";

const PrivacyPage = () => {
  return (
    <Container maxWidth="md">
      {allterms.terms.split("\n").map((t, i) => (
        <Typography variant="body1" key={i}>
          {t}
        </Typography>
      ))}
    </Container>
  );
};

export default PrivacyPage;
