import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import EyeIcon from "@material-ui/icons/Visibility";
// UI
import { ListItem } from "components";
// Utils
import { dateLegible } from "utils/dateUtils";
// Types
import { Magazine } from "types";

const useStyles = makeStyles({
  valueText: {
    marginLeft: 5,
  },
});

type MagazineListItemPRops = {
  magazine: Magazine;
  onItemPress(id: string): void;
  onDelete(id: string): void;
};

const MagazineListItem = (props: MagazineListItemPRops) => {
  const { magazine, onItemPress, onDelete, ...restProps } = props;
  const classes = useStyles();
  return (
    <ListItem
      {...restProps}
      key={magazine.id}
      onClick={() => onItemPress(magazine.id)}
      title={dateLegible(magazine.publishedAt)}
      subtitle={
        <>
          <Grid container direction="row" wrap="nowrap">
            <Typography variant="subtitle2">Categoria:</Typography>
            <Typography className={classes.valueText} noWrap variant="body2">
              {magazine.category.name}
            </Typography>
          </Grid>
          <Grid container direction="row" wrap="nowrap">
            <Typography variant="subtitle2">Editora:</Typography>

            <Typography className={classes.valueText} noWrap variant="body2">
              {magazine.publisher.name}
            </Typography>
          </Grid>
          <Grid container direction="row">
            <EyeIcon fontSize="small" />
            <Typography className={classes.valueText} variant="subtitle2">
              {magazine.viewCount}
            </Typography>
          </Grid>
        </>
      }
      onDelete={() => onDelete(magazine.id)}
      img={magazine.thumbnail}
    />
  );
};

export default React.memo(MagazineListItem);
