export const normalizePhone = (value: string): string => {
  if (!value) {
    return "";
  }
  let newNumber = value.replace(/\D/g, "");
  newNumber = newNumber.substring(0, 11);
  newNumber = newNumber.replace(/^(\d{2})(\d)/g, "$1 $2");
  newNumber = newNumber.replace(/(\d)(\d{4})$/, "$1-$2");
  return newNumber;
};

export const normalizeNums = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};
