import { forwardRef } from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
// Styles
import { useStyles } from "./LandingPage.styles";
// Assets
import { MockupMagazines } from "assets/images";

const LandingMagazines = forwardRef<HTMLElement>((_, ref) => {
  // const innerRef = useRef<HTMLElement>(ref!);
  const classes = useStyles();

  // useEffect(() => {
  //   innerRef.current
  // },[window])
  return (
    <section ref={ref} className={classes.magazinesSection}>
      <Container>
        <Grid container>
          <Grid item sm={12} md={5}>
            <Box mt={[3, 3, 0]} height="100%" display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h3" color="textSecondary">
                <Box fontWeight="500">Revistas</Box>
              </Typography>
              <Box mt={4} maxWidth="444px">
                <Typography variant="body1">
                  <Box lineHeight={"32px"}>
                    Você encontrará as edições do mês e edições anteriores das revistas{" "}
                    <strong>Seleções Destinos e Viajar pelo Mundo</strong> para ficar por dentro de todos detalhes e
                    novidades que fazem a diferença ao viajar
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={7}>
            <Box mt={[3, 3, 15]} display="flex" justifyContent="center" alignItems="flex-end">
              <img className={classes.magazinesImg} src={MockupMagazines} alt="revistas" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
});

export default LandingMagazines;
