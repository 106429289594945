import { useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useRouteMatch } from "react-router-dom";
import TemplateHeader from "./TemplateHeader";
// Services
import Storage from "services/Storage";
import { getUserInfo } from "api";
// State
import { useRecoilState } from "recoil";
import { snackbarState, userState } from "state/atoms";

interface TemplateProps {
  children?: React.ReactNode;
}

const Template = (props: TemplateProps) => {
  const { children } = props;
  const isLoginPage = !!useRouteMatch("/login");
  const [snack, setSnack] = useRecoilState(snackbarState);
  const [user, setUser] = useRecoilState(userState);

  const onCloseSnack = () => {
    setSnack((p) => ({ ...p, open: false }));
  };

  useEffect(() => {
    const userToken = Storage.getUserToken();
    async function getUserInfoFromToken() {
      const userInfo = await getUserInfo();
      setUser(userInfo);
    }
    if (userToken && !user) {
      getUserInfoFromToken();
    }
  }, [user]);

  return (
    <>
      {!isLoginPage && <TemplateHeader />}
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack.open}
        autoHideDuration={1500}
        onClose={onCloseSnack}
      >
        <Alert variant="filled" severity={snack.type} onClose={onCloseSnack} color={snack.type}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Template;
