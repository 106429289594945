import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Paper, makeStyles, Theme, Box, FormControlLabel, Checkbox } from "@material-ui/core";
// State
import { useSetRecoilState } from "recoil";
import { snackbarState, userState } from "state/atoms";
// Services
import { auth } from "api";
import Storage from "services/Storage";
// Form
import { Formik, Form } from "formik";
import * as yup from "yup";
// UI
import { Input, Button } from "components";
// Assets
import { Logo } from "assets/images";

type FormValues = {
  username: string;
  password: string;
  saveUser: boolean;
};

const loginValidationSchema = yup.object({
  username: yup.string().required("Campo obrigatório"),
  password: yup.string().min(6, "Precisa ter no minimo 6 dígitos").required("Campo obrigatório"),
});

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: "100vh",
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    position: "relative",
  },
  buttonLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  logoImage: {
    height: "3rem",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [userSaved, setUserSaved] = useState("");

  const setSnack = useSetRecoilState(snackbarState);
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const userToken = Storage.getUserToken();
    if (userToken) {
      history.push("/admin/revistas");
    }
  }, [history]);

  useEffect(() => {
    const userSaved = Storage.getUserSaved();
    if (userSaved) {
      setUserSaved(userSaved);
    }
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { username, password, saveUser } = values;
    try {
      const response = await auth({ username, password });
      if (saveUser) {
        Storage.addUserSaved(username);
      }
      Storage.addUserToken(response.token);
      setUser(response.user);
      history.push("/admin/revistas");
      setSnack({
        message: "Sucesso",
        open: true,
        type: "success",
      });
    } catch (err: any) {
      if (err?.message) {
        setSnack({
          message: err.message,
          open: true,
          type: "error",
        });
      }
    }
  };

  const initialValues: FormValues = {
    username: userSaved,
    password: "",
    saveUser: !!userSaved,
  };
  return (
    <Grid className={classes.gridContainer} container alignItems="center" justify="center">
      <Paper>
        <Box p={3}>
          <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
            <img src={Logo} className={classes.logoImage} alt="logo__image" />
          </Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => {
              return (
                <Form autoComplete="off">
                  <Input label="Usuário" name="username" fullWidth />
                  <Input label="Senha" name="password" type="password" fullWidth margin="normal" />
                  <Box my={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.saveUser}
                          color="primary"
                          onChange={(e) => setFieldValue("saveUser", e.target.checked)}
                          name="checkedA"
                        />
                      }
                      label="Lembrar"
                    />
                  </Box>
                  <Button variant="contained" color="primary" loading={isSubmitting} type="submit" fullWidth>
                    Entrar
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Grid>
  );
};

export default LoginPage;
