import React from "react";
import { Fab, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: Theme) => ({
  floatingButton: {
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
  },
}));

type FloatingAddButton = {
  onOpen(): void;
};

const FloatingAddButton = (props: FloatingAddButton) => {
  const classes = useStyles();
  const { onOpen } = props;
  return (
    <Fab className={classes.floatingButton} color="primary" aria-label="add" onClick={onOpen}>
      <AddIcon />
    </Fab>
  );
};

export default FloatingAddButton;
