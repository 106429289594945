import { forwardRef } from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
// Assets
import { MockupExplore } from "assets/images";
// Styles
import { useStyles } from "./LandingPage.styles";

const LandingTips = forwardRef<HTMLElement>((_, ref) => {
  const classes = useStyles();
  return (
    <section ref={ref}>
      <Container maxWidth="md">
        <Grid className={classes.tipsContainer} container>
          <Grid item sm={12} md>
            <Box mt={[4, 4, 15]} display="flex">
              <img className={classes.tipsImage} src={MockupExplore} alt="tips image" />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <Box pt={[4, 4, 0]} display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Typography variant="h3" color="textSecondary">
                <Box fontWeight="500">Dicas e curiosidades</Box>
              </Typography>
              <Box mt={4}>
                <Typography variant="body1">
                  <Box lineHeight={"32px"}>
                    Artigos fotográficos com curiosidades para você aprender mais sobre locais, diferentes culturas e
                    antigas tradições. E dicas importantes para você ficar sempre atualizado.
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
});

export default LandingTips;
