import fetch from "../services/Fetch";

// Types
import {
  UserAuthReq,
  UserAuthRes,
  UserWithoutPassword,
  MagazineCreateReq,
  MagazineUpdateReq,
  Magazine,
  Article,
  Category,
  Publisher,
  MsisdnStatusResponse,
} from "../types";

const auth = async (req: UserAuthReq): Promise<UserAuthRes> => {
  const { data } = await fetch.post<UserAuthRes>("/admin/auth", req);
  return data;
};

const getUserInfo = async (): Promise<UserWithoutPassword> => {
  const { data } = await fetch.get<UserWithoutPassword>("/admin/user");
  return data;
};

const createMagazine = async (req: MagazineCreateReq): Promise<Magazine> => {
  const formData = new FormData();
  if (req.file) {
    formData.append("pdf", req.file);
  }
  formData.append("categoryId", req.categoryId);
  formData.append("publisherId", req.publisherId);
  const { data } = await fetch.post<Magazine>("/admin/magazine", formData);
  return data;
};

const updateMagazine = async (req: MagazineUpdateReq): Promise<Magazine> => {
  const { data } = await fetch.put<Magazine>("/admin/magazine", req);
  return data;
};

const createArticle = async (req: File): Promise<Article> => {
  const formData = new FormData();
  formData.append("pdf", req);
  const { data } = await fetch.post<Magazine>("/admin/article", formData);
  return data;
};

const removeMagazine = async (id: string): Promise<void> => {
  await fetch.delete(`/admin/magazine/${id}`);
};
const removeArticle = async (id: string): Promise<void> => {
  await fetch.delete(`/admin/article/${id}`);
};
const removeNews = async (id: string): Promise<void> => {
  await fetch.delete(`/admin/news/${id}`);
};

const getMagazines = async (): Promise<Magazine[]> => {
  const { data } = await fetch.get<Magazine[]>("/magazines", { params: { getAll: "true" } });
  return data;
};

const getArticles = async (): Promise<Article[]> => {
  const { data } = await fetch.get<Article[]>("/articles");
  return data;
};

const getCategories = async (): Promise<Category[]> => {
  const { data } = await fetch.get<Category[]>("/admin/category");
  return data;
};
const getPublishers = async (): Promise<Publisher[]> => {
  const { data } = await fetch.get<Publisher[]>("/admin/publisher");
  return data;
};

const getMsisdnStatus = async (msisdn: string): Promise<MsisdnStatusResponse> => {
  const { data } = await fetch.get<MsisdnStatusResponse>(`/admin/status/${msisdn}`);
  return data;
};

export {
  createArticle,
  createMagazine,
  updateMagazine,
  getArticles,
  getMagazines,
  getPublishers,
  getCategories,
  removeArticle,
  removeNews,
  removeMagazine,
  auth,
  getMsisdnStatus,
  getUserInfo,
};
