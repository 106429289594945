import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from "@material-ui/core";
// Form
import { Formik, Form } from "formik";
import * as yup from "yup";
// UI
import { Button, DropzoneInput } from "components";

const validationSchema = yup.object({
  file: yup.mixed().required("Você precisa escolher um arquivo"),
});

const useStyles = makeStyles({
  dialogContent: {
    width: 400,
  },
});

interface DialogFormProps<T> {
  onCloseDialog(): void;
  open: boolean;
  initialValues: T;
  onSubmit(e: T): void;
}

function DialogForm<T>(props: DialogFormProps<T>) {
  const classes = useStyles();
  const { open, onCloseDialog, onSubmit, initialValues } = props;

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form>
              <DialogTitle id="form-dialog-title">Novo Artigo</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <DropzoneInput name="file" />
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={onCloseDialog} color="primary">
                  Cancelar
                </Button>
                <Button loading={isSubmitting} type="submit" color="primary" variant="contained">
                  Criar
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default DialogForm;
