import React from "react";
import { Button, CircularProgress, ButtonProps, makeStyles } from "@material-ui/core";

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    position: "relative",
  },
  buttonLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "white",
  },
}));

const CustomButton = (props: CustomButtonProps) => {
  const classes = useStyles();
  const { loading, disabled, ...restProps } = props;
  return (
    <div className={classes.buttonWrapper}>
      <Button disabled={disabled || loading} {...restProps} />
      {restProps.variant === "contained" && loading && <CircularProgress size={24} className={classes.buttonLoading} />}
    </div>
  );
};

export default CustomButton;
