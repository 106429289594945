import { useRecoilValue } from "recoil";
import { sortAndFilterMagazinesList } from "state/atoms";
// Types
// UI
import { List } from "components";
// Components
import MagazineListItem from "./MagazineListItem";
import MagazineListHeader from "./MagazineListHeader";

interface MagazineListProps {
  onItemPress: (id: string) => void;
  onStartDelete: (id: string) => void;
}

const MagazineList = (props: MagazineListProps) => {
  const magazinesList = useRecoilValue(sortAndFilterMagazinesList);
  const { onItemPress, onStartDelete } = props;
  return (
    <List
      title="Revistas"
      emptyMessage="Nenhuma Revista encontrada"
      data={magazinesList}
      cellHeight={300}
      header={<MagazineListHeader />}
      renderItem={(mag) => <MagazineListItem onItemPress={onItemPress} magazine={mag} onDelete={onStartDelete} />}
    />
  );
};

export default MagazineList;
