enum STORAGE_KEYS {
  USER_TOKEN = "oidestinos@user_token",
  USER_SAVED = "oidestinos@user_saved",
}

function getUserToken(): string {
  return localStorage.getItem(STORAGE_KEYS.USER_TOKEN) || "";
}
function removeUserToken(): void {
  return localStorage.removeItem(STORAGE_KEYS.USER_TOKEN);
}
function addUserToken(token: string): void {
  return localStorage.setItem(STORAGE_KEYS.USER_TOKEN, token);
}
function getUserSaved(): string {
  return localStorage.getItem(STORAGE_KEYS.USER_SAVED) || "";
}
function removeUserSaved(): void {
  return localStorage.removeItem(STORAGE_KEYS.USER_SAVED);
}
function addUserSaved(token: string): void {
  return localStorage.setItem(STORAGE_KEYS.USER_SAVED, token);
}

const storage = {
  getUserToken,
  removeUserToken,
  addUserToken,
  getUserSaved,
  removeUserSaved,
  addUserSaved,
};

export default storage;
