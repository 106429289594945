import React, { useMemo } from "react";
import {
  GridList,
  Box,
  Container,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
  List,
  makeStyles,
  Theme,
} from "@material-ui/core";

interface ListProps<T> {
  title: string;
  emptyMessage: string;
  data: T[];
  header?: React.ReactNode;
  renderItem(item: T, index: number): JSX.Element;
  cellHeight: number;
  useSimpleList: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  listRoot: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: 4,
  },
}));

function CustomList<T>(props: ListProps<T>) {
  const classes = useStyles();
  const { title, data, emptyMessage, renderItem, cellHeight, useSimpleList, header } = props;
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const cols = useMemo(() => {
    if (isXsDown) {
      return 2;
    } else if (isSmUp) {
      return 4;
    }
    return 3;
  }, [isSmUp, isXsDown]);

  return (
    <Container maxWidth="md">
      <Box my={3}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {data.length === 0 && (
        <Paper>
          <Box p={3}>
            <Typography variant="body2">{emptyMessage}</Typography>
          </Box>
        </Paper>
      )}
      {header && header}
      {useSimpleList && data.length > 0 ? (
        <List className={classes.listRoot}>{data.map((item, index) => renderItem(item, index))}</List>
      ) : (
        <GridList cols={cols} cellHeight={cellHeight} spacing={20}>
          {data.map((item, index) => React.cloneElement(renderItem(item, index), { key: index }))}
        </GridList>
      )}
    </Container>
  );
}

CustomList.defaultProps = {
  cellHeight: 240,
  useSimpleList: false,
};

export default CustomList;
