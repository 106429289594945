import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

interface ModalDeleteProps {
  open: boolean;
  onClose(): void;
  title: string;
  onSuccess(): void;
}

const ModalDelete = (props: ModalDeleteProps) => {
  const { open, onClose, onSuccess, title } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSuccess} color="primary" autoFocus>
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDelete;
