import React, { useEffect, useState, useMemo } from "react";
// State
import { useSetRecoilState, useRecoilValue } from "recoil";
import { snackbarState, useRefreshMagazines, useMagazineQuery, userState } from "state/atoms";
// UI
import { Loading, ModalDelete, FloatingAddButton } from "components";
// Services
import { createMagazine, removeMagazine, getCategories, updateMagazine } from "api";
// Types
import { Category, MagazineCreateReq } from "types";
// Utils
import { dateLegible } from "utils/dateUtils";
// Page Components
import MagazineList from "./components/MagazineList";
import NewMagazineFormDialog from "./components/NewMagazineForm";
import MagazineDialog from "./components/MagazineDialog";

type MagazineInitialValues = MagazineCreateReq;

const MagazinePageView = () => {
  const magazines = useRecoilValue(useMagazineQuery);
  const magazinesRefresh = useRefreshMagazines();
  const setSnack = useSetRecoilState(snackbarState);
  const user = useRecoilValue(userState);

  const [categories, setCategories] = useState<Category[]>([]);
  // Modal Control
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [choiceDialogOpen, setChoiceDialogOpen] = useState(false);
  // Update/Delete Control
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string>();
  const [isEditing, setIsEditing] = useState(false);
  const [magazineUpdateId, setMagazineUpdateId] = useState("");

  const openFormDialog = () => {
    setFormDialogOpen(true);
  };
  const closeFormDialog = () => {
    setFormDialogOpen(false);
    setIsEditing(false);
    setMagazineUpdateId("");
  };

  const onOpenModalDelete = (id: string) => {
    setDeleteModalOpen(true);
    setDeleteItemId(id);
  };
  const onCloseModalDelete = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    const fetchInitialSelectors = async () => {
      const categories = await getCategories();
      setCategories(categories);
    };
    fetchInitialSelectors();
  }, []);

  const onDeleteConfirm = async () => {
    if (deleteItemId) {
      setLoading(true);
      onCloseModalDelete();
      await removeMagazine(deleteItemId);
      setLoading(false);
      magazinesRefresh();
    }
  };

  const onSubmit = async (values: MagazineInitialValues) => {
    try {
      if (isEditing) {
        const newValues = {
          ...values,
          publishedAt: new Date(values.publishedAt),
          id: magazineUpdateId,
        };
        await updateMagazine(newValues);
      } else {
        await createMagazine(values);
      }
      setSnack({
        open: true,
        type: "success",
        message: isEditing ? "Editado com sucesso" : "Criado com sucesso",
      });
      onCloseChoiceDialog();
      closeFormDialog();
      magazinesRefresh();
    } catch (error: any) {
      setSnack({
        open: true,
        type: "error",
        message: error?.message || "",
      });
    }
  };

  const magazineSelectedDay = useMemo(() => {
    if (deleteItemId) {
      const itemFromList = magazines.find((mag) => mag.id === deleteItemId);

      return itemFromList ? dateLegible(itemFromList?.createdAt) : "";
    }
  }, [deleteItemId]);

  function onItemPress(id: string) {
    setChoiceDialogOpen(true);
    setMagazineUpdateId(id);
  }

  function onCloseChoiceDialog() {
    setIsEditing(false);
    setChoiceDialogOpen(false);
    setMagazineUpdateId("");
  }

  function onStartUpdate() {
    setIsEditing(true);
    setChoiceDialogOpen(false);
    openFormDialog();
  }

  function onGoPdf() {
    if (magazineUpdateId) {
      const mag = magazines.find((mg) => mg.id === magazineUpdateId);
      if (mag) {
        window.open(mag.pdfUrl, "_blank");
      }
    }
  }

  const initialValues: MagazineInitialValues = useMemo(() => {
    if (isEditing && magazineUpdateId) {
      const mag = magazines.find((mg) => mg.id === magazineUpdateId);
      if (mag) {
        return {
          categoryId: mag.categoryId,
          publishedAt: dateLegible(mag.publishedAt, "yyyy-MM-dd"),
          publisherId: mag.publisherId,
        };
      }
    }
    return {
      categoryId: "",
      publisherId: "",
      file: undefined,
      publishedAt: dateLegible(new Date(), "yyyy-MM-dd"),
    };
  }, [magazineUpdateId, isEditing, magazines]);

  return (
    <>
      {loading && <Loading />}
      <MagazineList onItemPress={onItemPress} onStartDelete={onOpenModalDelete} />
      <FloatingAddButton onOpen={openFormDialog} />
      <NewMagazineFormDialog
        open={formDialogOpen}
        onCloseDialog={closeFormDialog}
        onSubmit={onSubmit}
        categories={categories}
        isEditing={isEditing}
        initialValues={initialValues}
      />
      {user?.role === "ADMIN" && (
        <ModalDelete
          open={deleteModalOpen}
          onClose={onCloseModalDelete}
          title={`Deseja remover a revista do dia ${magazineSelectedDay}?`}
          onSuccess={onDeleteConfirm}
        />
      )}

      <MagazineDialog
        open={choiceDialogOpen}
        onUpdate={onStartUpdate}
        onOpenPdf={onGoPdf}
        onClose={onCloseChoiceDialog}
      />
    </>
  );
};

const MagazinePage = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <MagazinePageView />
    </React.Suspense>
  );
};

export default MagazinePage;
