import React from "react";
import { makeStyles, GridListTile, GridListTileBar, IconButton, Theme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    cursor: "pointer",
    boxShadow: theme.shadows[3],
    borderRadius: 4,
    transition: "box-shadow .3s ease",
    "&:hover": {
      boxShadow: theme.shadows[5],
    },
  },
  actionBarBigger: {
    height: "auto",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

interface ListItemProps {
  onClick(): void;
  title: string;
  img?: string;
  onDelete(): void;
  subtitle?: React.ReactNode;
  style?: React.CSSProperties;
}

const ListItem = (props: ListItemProps) => {
  const classes = useStyles();
  const { onDelete, onClick, title, subtitle, img, style } = props;
  return (
    <GridListTile
      onClick={onClick}
      style={style}
      classes={{
        tile: classes.card,
      }}
    >
      {img && <img src={img} alt={img} />}
      <GridListTileBar
        title={title}
        {...(subtitle && {
          subtitle,
        })}
        classes={{
          root: subtitle ? classes.actionBarBigger : "",
        }}
        actionIcon={
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            aria-label={`delete item`}
          >
            <DeleteIcon color="secondary" />
          </IconButton>
        }
      />
    </GridListTile>
  );
};

export default ListItem;
