import React from "react";
import { render } from "react-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { RecoilRoot } from "recoil";
import theme from "./theme";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";

render(
  <ThemeProvider theme={theme}>
    <RecoilRoot>
      <CssBaseline />
      <Routes />
    </RecoilRoot>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
