import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Services
import Storage from "services/Storage";
// Material Core
import { Theme, AppBar, Toolbar, Drawer, IconButton, makeStyles, Typography } from "@material-ui/core";
// Material Icons
import MenuIcon from "@material-ui/icons/Menu";
import DrawerList from "./DrawerList";

const TitleByPages = {
  "/": "Dashboard",
  "/revistas": "Revistas",
  "/artigos": "Artigos",
  // "/noticias": "Notícias",
};

type Pages = keyof typeof TitleByPages;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 200,
  },
  toolbar: {
    background: "linear-gradient(304.78deg, #B065CC 0%, #349CCC 100%)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    background: "initial",
  },
}));

const TemplateHeader = () => {
  const classes = useStyles();
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const onOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const onLogout = () => {
    Storage.removeUserToken();
    history.push("/login");
  };

  const onGoRoute = (route: string) => {
    onCloseDrawer();
    history.push(route);
  };

  const title = TitleByPages[history.location.pathname as Pages];

  return (
    <>
      <Drawer anchor="left" onClose={onCloseDrawer} open={drawerOpen}>
        <div role="presentation" className={classes.drawer}>
          <DrawerList onLogout={onLogout} onGoPage={onGoRoute} activedPage={history.location.pathname} />
        </div>
      </Drawer>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            onClick={onOpenDrawer}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography color="inherit" variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TemplateHeader;
