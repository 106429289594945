// UI
import { Box, BoxProps } from "@material-ui/core";
// Styles
import { useStyles } from "./LandingPage.styles";
// Assets
import { AppStore, PlayStore } from "assets/images";
import config from "config";

type LandingStoreButtonsProps = BoxProps;

const LandingStoreButton = (props: LandingStoreButtonsProps) => {
  const classes = useStyles();
  return (
    <Box {...props} display="flex">
      <Box mr={2}>
        <a href={config.android} className={classes.storeButtonIcon}>
          <img className={classes.storeImage} src={PlayStore} alt="playstore image" />
        </a>
      </Box>
      <a href={config.ios} className={classes.storeButtonIcon}>
        <img className={classes.storeImage} src={AppStore} alt="appstore image" />
      </a>
    </Box>
  );
};

export default LandingStoreButton;
